<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <div class="row">
      <div class="col-12">
        <v-alert color="cyan" border="left" elevation="2" colored-border>
          <v-form ref="form" v-model="isFormValid" class="w-100">
            <div class="row">
              <div class="col-12">
                <v-text-field
                  v-model="name"
                  :counter="255"
                  :rules="name_rules"
                  label="Güç Santrali Tip Adı"
                  required
                ></v-text-field>
              </div>
              <div class="col-12 text-center">
                <v-btn
                  :disabled="!isFormValid"
                  color="error"
                  class="mr-4 bg-success"
                  @click="update"
                >
                  Kaydet
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import customBreadcrumb from "@/view/custom/breadcrumb";
import {
  GET_STATION_TYPE,
  UPDATE_STATION_TYPE,
} from "@/core/services/store/stationTypes.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  name: "station-types.edit",
  components: {
    customBreadcrumb,
  },
  data() {
    return {
      customBreadcrumb: {
        title: "Güç Santral Tipleri",
        type: "Düzenle",
        pathName: null,
      },
      //Form Validation
      isFormValid: false,
      name: "",
      name_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => (v && v.length <= 255) || "Ad 255 karakterden az olmalıdır.",
      ],
    };
  },
  methods: {
    update() {
      if (this.isFormValid) {
        let name = this.name;
        let id = parseInt(this.$route.params.id);
        this.$store
          .dispatch(UPDATE_STATION_TYPE, {
            id,
            name,
          })
          .then((res) => {
            this.$router.push({ name: "station-types" });
          })
          .catch((err) => {});
      }
    },
  },
  // computed:{
  //     ...mapGetters(['getArea','getStations'])
  // },
  created() {
    this.$store
      .dispatch(GET_STATION_TYPE, this.$route.params.id)
      .then((res) => {
        this.name = res.name;
      });
  },
  computed: { ...mapGetters(["breadcrumbs", "pageTitle"]) },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Santral tipleri",
        route: "/station-types",
        icon: "flaticon-car",
      },
      { title: "Düzenle" },
    ]);
  },
};
</script>

<style scoped>
</style>
